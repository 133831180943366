import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import Unit from "../molecules/unit";

import productImg from "../../images/units/Product.svg";
import qaImg from "../../images/units/QA.svg";
import designImg from "../../images/units/Design.svg";
import contentImg from "../../images/units/Content.svg";
import frontendImg from "../../images/units/Frontend.svg";
import backendImg from "../../images/units/Backend.svg";
import businesImg from "../../images/units/Busines.svg";
import b2bImg from "../../images/units/B2B.svg";
import marketingImg from "../../images/units/Marketing.svg";
import supportImg from "../../images/units/Support.svg";
import dataImg from "../../images/units/Data.svg";
import communityImg from "../../images/units/Community.svg";

import { pushClickEventToDataLayer } from "../atoms/gtmHelper";




export default function Units(props) {
  return (
    <Row>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={productImg} name="Product." unitTitle="Die Kommandobrücke macht aus schwarzen Löchern Fahrpläne für unsere Raumpilot:innen." />
        <Row>
        <Col md={12} className="d-flex justify-content-start">
          <Link onClick={(e) => pushClickEventToDataLayer(e)} className="mt-4 btn btn-primary btn-hi" id="jobs-product" to="/jobs/">Noch freie Plätze an Bord!</Link>
        </Col>
      </Row>
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={qaImg} name="QA." unitTitle="Unser Abwehrsystem – präzise und gefürchtet – an denen kommt keiner vorbei."/>
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={designImg} name="Design." unitTitle="Schenkt unserer Mission einen galaktischen Look – angefeuert von zündenden Ideen."/>
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={contentImg} name="Content." unitTitle="Kreiert Inhalte-Galaxien aus kleinsten Teilchen und den schlausten Köpfen."/>
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={frontendImg} name="Frontend." unitTitle="Konstruiert unsere Raketen so, dass am Ende die Flammen unten und das Konfetti oben rauskommt."/>
        <Row>
        <Col md={12} className="d-flex justify-content-start">
          <Link onClick={(e) => pushClickEventToDataLayer(e)} className="mt-4 btn btn-primary btn-hi" id="jobs-frontend" to="/jobs/">Noch freie Plätze an Bord!</Link>
        </Col>
      </Row>
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={backendImg} name="Backend." unitTitle="Baut mit PostgreSQL, MySQL und AWS Raumschiffe aus Ruby on Rails." />
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={businesImg} name="Business." unitTitle="Schafft, dass es unsere kleine Mission mit den ganz Großen aufnehmen kann." />
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={b2bImg} name="B2B" unitTitle="Schickt unsere Raumsonden in entfernte Galaxien. Und kommt mit neuen Ideen zurück." />
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={marketingImg} name="Marketing." unitTitle="Erzählt jeder Galaxie von unserer Mission. Per Text, Code oder Bildsprache."/>
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={supportImg} name="Support." unitTitle="Ist für unsere Passagiere da. Ganz egal, wo der Raumanzug drückt."/>
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={dataImg} name="Data." unitTitle="Kennt unsere Formel für ein leichtes Leben und weiß, wem wir sie schon verraten haben."/>
      </Col>
      <Col className="unit-col" sm={6} lg={4} xl={3}>
        <Unit unitImg={communityImg} name="Community." unitTitle="Versteht sich auf Likes, Klicks und Kommentare und zeigt dem ganzen Kosmos, was wir zu bieten haben."/>
      </Col>
    </Row>
  )
}


