import React from "react"


export default function Unit(props) {
  return (
    <div className="unit">
      <img
        className="unit-img"
        src={props.unitImg}
        width="100%"
        alt={props.name}
      />
      <h2>{props.name}</h2> 
      <h3 className="sub-title">{props.unitTitle}</h3>
      <p>{props.unitText}</p>
    </div>
  )
}
