import React from "react";
import { Container } from "react-bootstrap";

import Layout from "../components/templates/layout";
import SEO from "../components/templates/seo";

import Hero from "../components/organisms/hero";

import Units from "../components/organisms/units";

const Hi = () => (
  <Layout pageInfo={{ pageName: "hi" }}>
    <SEO title="MissionMe - Hi" />
    <Container fluid="md">
      <Hero
        showDots={false}
        inviteMail
        headingPreHeadline="Werde Teil unserer irdischen Mission!"
        headingHeadline="Wir unterstützen die Menschheit auf dem Weg zu ihrem besseren Ich!"
        introHeadline="Willkommen bei MissionMe!"
        introText="Schön, dass dich unsere galaktischen Weisheiten hergeführt haben. Unsere gemeinsame Mission: Wir möchten das Leben leichter machen – ob über einen Trip mit dem Raumschiff oder durch unsere audiobasierten Smartphone-Apps. Daran arbeiten wir über alle Galaxien hinweg."
        introImg={
          <svg
            version="1.1"
            baseProfile="basic"
            id="graphic_x5F_hi"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 339 319.7"
          >
            <path
              id="hexa_x5F_purple"
              fill="#662483"
              d="M52,44L12.7,112L52,179.9h78.5l39.2-67.9L130.4,44H52 M47,35.4h88.4l44.2,76.6
       l-44.2,76.6H47L2.7,112L47,35.4z"
            />
            <path
              id="hexa_x5F_yellow"
              fill="#F39200"
              d="M174.3,138.2h63.1l31.5,54.6l-31.5,54.6h-63.1l-31.5-54.6L174.3,138.2z"
            />
            <path
              id="hexa_x5F_red"
              fill="#EA504C"
              d="M129.7,84.7l-5.4,30.3l23.6,19.8l29-10.5l5.4-30.3l-23.6-19.8L129.7,84.7 M127.4,82.7
       l31.8-11.6l26,21.8l-5.9,33.4l-31.8,11.6l-26-21.8L127.4,82.7z"
            />
            <path
              id="hexa_x5F_green"
              fill="#009E7B"
              d="M106.5,220.2l-8.3,14.4l8.3,14.4h16.7l8.3-14.4l-8.3-14.4H106.5 M93.4,197.6h42.8
       l21.4,37.1l-21.4,37.1H93.4L72,234.7L93.4,197.6z"
            />
            <g
              id="dots"
              transform="matrix(0.899, -0.438, 0.438, 0.899, 1085.456, 217.171)"
            >
              <g id="Gruppe_314" transform="translate(189.329 130.396)">
                <circle
                  id="Ellipse_419"
                  fill="#009E7B"
                  cx="-1054.2"
                  cy="-671.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_420"
                  fill="#009E7B"
                  cx="-1039.5"
                  cy="-677.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_421"
                  fill="#009E7B"
                  cx="-1024.9"
                  cy="-683.2"
                  r="2.6"
                />
                <circle
                  id="Ellipse_422"
                  fill="#009E7B"
                  cx="-1010.2"
                  cy="-689.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_423"
                  fill="#009E7B"
                  cx="-995.5"
                  cy="-695"
                  r="2.6"
                />
                <circle
                  id="Ellipse_424"
                  fill="#009E7B"
                  cx="-980.9"
                  cy="-700.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_425"
                  fill="#009E7B"
                  cx="-966.2"
                  cy="-706.7"
                  r="2.6"
                />
                <circle
                  id="Ellipse_426"
                  fill="#009E7B"
                  cx="-951.5"
                  cy="-712.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_427"
                  fill="#009E7B"
                  cx="-936.9"
                  cy="-718.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_428"
                  fill="#009E7B"
                  cx="-922.2"
                  cy="-724.4"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_315" transform="translate(198.182 152.438)">
                <circle
                  id="Ellipse_429"
                  fill="#009E7B"
                  cx="-1057.1"
                  cy="-678.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_430"
                  fill="#009E7B"
                  cx="-1042.5"
                  cy="-684.7"
                  r="2.6"
                />
                <circle
                  id="Ellipse_431"
                  fill="#009E7B"
                  cx="-1027.8"
                  cy="-690.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_432"
                  fill="#009E7B"
                  cx="-1013.2"
                  cy="-696.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_433"
                  fill="#009E7B"
                  cx="-998.5"
                  cy="-702.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_434"
                  fill="#009E7B"
                  cx="-983.8"
                  cy="-708.2"
                  r="2.6"
                />
                <circle
                  id="Ellipse_435"
                  fill="#009E7B"
                  cx="-969.2"
                  cy="-714.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_436"
                  fill="#009E7B"
                  cx="-954.5"
                  cy="-720"
                  r="2.6"
                />
                <circle
                  id="Ellipse_437"
                  fill="#009E7B"
                  cx="-939.8"
                  cy="-725.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_438"
                  fill="#009E7B"
                  cx="-925.2"
                  cy="-731.8"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_316" transform="translate(207.037 174.478)">
                <circle
                  id="Ellipse_439"
                  fill="#009E7B"
                  cx="-1060.1"
                  cy="-686.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_440"
                  fill="#009E7B"
                  cx="-1045.4"
                  cy="-692"
                  r="2.6"
                />
                <circle
                  id="Ellipse_441"
                  fill="#009E7B"
                  cx="-1030.8"
                  cy="-697.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_442"
                  fill="#009E7B"
                  cx="-1016.1"
                  cy="-703.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_443"
                  fill="#009E7B"
                  cx="-1001.5"
                  cy="-709.7"
                  r="2.6"
                />
                <circle
                  id="Ellipse_444"
                  fill="#009E7B"
                  cx="-986.8"
                  cy="-715.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_445"
                  fill="#009E7B"
                  cx="-972.1"
                  cy="-721.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_446"
                  fill="#009E7B"
                  cx="-957.5"
                  cy="-727.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_447"
                  fill="#009E7B"
                  cx="-942.8"
                  cy="-733.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_448"
                  fill="#009E7B"
                  cx="-928.1"
                  cy="-739.2"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_317" transform="translate(215.891 196.52)">
                <circle
                  id="Ellipse_449"
                  fill="#009E7B"
                  cx="-1063.1"
                  cy="-693.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_450"
                  fill="#009E7B"
                  cx="-1048.4"
                  cy="-699.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_451"
                  fill="#009E7B"
                  cx="-1033.7"
                  cy="-705.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_452"
                  fill="#009E7B"
                  cx="-1019.1"
                  cy="-711.2"
                  r="2.6"
                />
                <circle
                  id="Ellipse_453"
                  fill="#009E7B"
                  cx="-1004.4"
                  cy="-717.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_454"
                  fill="#009E7B"
                  cx="-989.7"
                  cy="-723"
                  r="2.6"
                />
                <circle
                  id="Ellipse_455"
                  fill="#009E7B"
                  cx="-975.1"
                  cy="-728.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_456"
                  fill="#009E7B"
                  cx="-960.4"
                  cy="-734.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_457"
                  fill="#009E7B"
                  cx="-945.8"
                  cy="-740.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_458"
                  fill="#009E7B"
                  cx="-931.1"
                  cy="-746.5"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_318" transform="translate(224.746 218.561)">
                <circle
                  id="Ellipse_459"
                  fill="#009E7B"
                  cx="-1066"
                  cy="-700.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_460"
                  fill="#009E7B"
                  cx="-1051.4"
                  cy="-706.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_461"
                  fill="#009E7B"
                  cx="-1036.7"
                  cy="-712.7"
                  r="2.6"
                />
                <circle
                  id="Ellipse_462"
                  fill="#009E7B"
                  cx="-1022"
                  cy="-718.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_463"
                  fill="#009E7B"
                  cx="-1007.4"
                  cy="-724.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_464"
                  fill="#009E7B"
                  cx="-992.7"
                  cy="-730.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_465"
                  fill="#009E7B"
                  cx="-978"
                  cy="-736.2"
                  r="2.6"
                />
                <circle
                  id="Ellipse_466"
                  fill="#009E7B"
                  cx="-963.4"
                  cy="-742.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_467"
                  fill="#009E7B"
                  cx="-948.7"
                  cy="-748"
                  r="2.6"
                />
                <circle
                  id="Ellipse_468"
                  fill="#009E7B"
                  cx="-934.1"
                  cy="-753.9"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_319" transform="translate(233.601 240.601)">
                <circle
                  id="Ellipse_469"
                  fill="#009E7B"
                  cx="-1069"
                  cy="-708.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_470"
                  fill="#009E7B"
                  cx="-1054.3"
                  cy="-714.2"
                  r="2.6"
                />
                <circle
                  id="Ellipse_471"
                  fill="#009E7B"
                  cx="-1039.7"
                  cy="-720"
                  r="2.6"
                />
                <circle
                  id="Ellipse_472"
                  fill="#009E7B"
                  cx="-1025"
                  cy="-725.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_473"
                  fill="#009E7B"
                  cx="-1010.3"
                  cy="-731.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_474"
                  fill="#009E7B"
                  cx="-995.7"
                  cy="-737.7"
                  r="2.6"
                />
                <circle
                  id="Ellipse_475"
                  fill="#009E7B"
                  cx="-981"
                  cy="-743.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_476"
                  fill="#009E7B"
                  cx="-966.3"
                  cy="-749.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_477"
                  fill="#009E7B"
                  cx="-951.7"
                  cy="-755.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_478"
                  fill="#009E7B"
                  cx="-937"
                  cy="-761.3"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_320" transform="translate(242.454 262.643)">
                <circle
                  id="Ellipse_479"
                  fill="#009E7B"
                  cx="-1072"
                  cy="-715.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_480"
                  fill="#009E7B"
                  cx="-1057.3"
                  cy="-721.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_481"
                  fill="#009E7B"
                  cx="-1042.6"
                  cy="-727.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_482"
                  fill="#009E7B"
                  cx="-1028"
                  cy="-733.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_483"
                  fill="#009E7B"
                  cx="-1013.3"
                  cy="-739.2"
                  r="2.6"
                />
                <circle
                  id="Ellipse_484"
                  fill="#009E7B"
                  cx="-998.6"
                  cy="-745.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_485"
                  fill="#009E7B"
                  cx="-984"
                  cy="-751"
                  r="2.6"
                />
                <circle
                  id="Ellipse_486"
                  fill="#009E7B"
                  cx="-969.3"
                  cy="-756.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_487"
                  fill="#009E7B"
                  cx="-954.6"
                  cy="-762.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_488"
                  fill="#009E7B"
                  cx="-940"
                  cy="-768.7"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_321" transform="translate(251.309 284.684)">
                <circle
                  id="Ellipse_489"
                  fill="#009E7B"
                  cx="-1074.9"
                  cy="-723"
                  r="2.6"
                />
                <circle
                  id="Ellipse_490"
                  fill="#009E7B"
                  cx="-1060.3"
                  cy="-728.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_491"
                  fill="#009E7B"
                  cx="-1045.6"
                  cy="-734.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_492"
                  fill="#009E7B"
                  cx="-1030.9"
                  cy="-740.7"
                  r="2.6"
                />
                <circle
                  id="Ellipse_493"
                  fill="#009E7B"
                  cx="-1016.3"
                  cy="-746.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_494"
                  fill="#009E7B"
                  cx="-1001.6"
                  cy="-752.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_495"
                  fill="#009E7B"
                  cx="-986.9"
                  cy="-758.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_496"
                  fill="#009E7B"
                  cx="-972.3"
                  cy="-764.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_497"
                  fill="#009E7B"
                  cx="-957.6"
                  cy="-770.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_498"
                  fill="#009E7B"
                  cx="-942.9"
                  cy="-776"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_322" transform="translate(260.163 306.725)">
                <circle
                  id="Ellipse_499"
                  fill="#009E7B"
                  cx="-1077.9"
                  cy="-730.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_500"
                  fill="#009E7B"
                  cx="-1063.2"
                  cy="-736.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_501"
                  fill="#009E7B"
                  cx="-1048.6"
                  cy="-742.2"
                  r="2.6"
                />
                <circle
                  id="Ellipse_502"
                  fill="#009E7B"
                  cx="-1033.9"
                  cy="-748.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_503"
                  fill="#009E7B"
                  cx="-1019.2"
                  cy="-754"
                  r="2.6"
                />
                <circle
                  id="Ellipse_504"
                  fill="#009E7B"
                  cx="-1004.6"
                  cy="-759.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_505"
                  fill="#009E7B"
                  cx="-989.9"
                  cy="-765.7"
                  r="2.6"
                />
                <circle
                  id="Ellipse_506"
                  fill="#009E7B"
                  cx="-975.2"
                  cy="-771.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_507"
                  fill="#009E7B"
                  cx="-960.6"
                  cy="-777.5"
                  r="2.6"
                />
                <circle
                  id="Ellipse_508"
                  fill="#009E7B"
                  cx="-945.9"
                  cy="-783.4"
                  r="2.6"
                />
              </g>
              <g id="Gruppe_323" transform="translate(269.018 328.766)">
                <circle
                  id="Ellipse_509"
                  fill="#009E7B"
                  cx="-1080.9"
                  cy="-737.8"
                  r="2.6"
                />
                <circle
                  id="Ellipse_510"
                  fill="#009E7B"
                  cx="-1066.2"
                  cy="-743.7"
                  r="2.6"
                />
                <circle
                  id="Ellipse_511"
                  fill="#009E7B"
                  cx="-1051.5"
                  cy="-749.6"
                  r="2.6"
                />
                <circle
                  id="Ellipse_512"
                  fill="#009E7B"
                  cx="-1036.9"
                  cy="-755.4"
                  r="2.6"
                />
                <circle
                  id="Ellipse_513"
                  fill="#009E7B"
                  cx="-1022.2"
                  cy="-761.3"
                  r="2.6"
                />
                <circle
                  id="Ellipse_514"
                  fill="#009E7B"
                  cx="-1007.5"
                  cy="-767.2"
                  r="2.6"
                />
                <circle
                  id="Ellipse_515"
                  fill="#009E7B"
                  cx="-992.9"
                  cy="-773.1"
                  r="2.6"
                />
                <circle
                  id="Ellipse_516"
                  fill="#009E7B"
                  cx="-978.2"
                  cy="-779"
                  r="2.6"
                />
                <circle
                  id="Ellipse_517"
                  fill="#009E7B"
                  cx="-963.5"
                  cy="-784.9"
                  r="2.6"
                />
                <circle
                  id="Ellipse_518"
                  fill="#009E7B"
                  cx="-948.9"
                  cy="-790.8"
                  r="2.6"
                />
              </g>
            </g>
            <rect fill="none" width="339" height="319.7" />
          </svg>
        }
      />
      <Units />
    </Container>
  </Layout>
);

export default Hi;
